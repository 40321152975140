<template>
	<div>
		<el-form :inline="true" @submit.native.prevent @keyup.enter.native.prevent="search">
			<el-form-item>
				<el-input v-model="title" placeholder="协议标题" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-input v-model="policyId" placeholder="协议ID" clearable></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="search()">查询</el-button>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" @click="addOrUpdateHandle()">新增</el-button>
			</el-form-item>
		</el-form>
		<el-table :data="dataList" border style="width: 100%;">
			<el-table-column prop="policyId" header-align="center" align="center" label="协议ID"></el-table-column>
			<el-table-column prop="title" header-align="center" align="center" label="标题"></el-table-column>
			<el-table-column prop="status" header-align="center" align="center" label="状态">
				<span slot-scope="scope">
					<!-- {{
						scope.row.status === 1 ? '启用' : '禁用'
					}} -->
					<el-switch
						v-model="scope.row.status"
						@change="changeStatus(scope.row)"
						active-color="#13ce66"
						inactive-color="#ff4949"
						:active-value="1"
						:inactive-value="2"
						:active-text="scope.row.status === 1 ? '已启用' : '已禁用'"
					>
					</el-switch>
				</span>
			</el-table-column>
			<el-table-column header-align="center" align="center" width="150" label="操作">
				<template slot-scope="scope">
					<el-button type="text" size="small" @click="addOrUpdateHandle(scope.row.id)">修改</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div style="float: right; margin-top: 20px;">
			<el-pagination
				@size-change="handleSizeChange"
				@current-change="handleCurrentChange"
				:current-page="currentPage"
				:page-sizes="[10, 20, 50, 100]"
				:page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			>
			</el-pagination>
		</div>
	</div>
</template>
<script>
import { dateFormat } from '@/utils';
export default {
	data () {
		return {
			title: '',
			policyId: '',
			dataList: [],
			currentPage: 1,
			pageSize: 10,
			total: 0
		};
	},
	created () {
		this.search();
	},
	methods: {
		search () {
			this.pageSize = 10;
			this.total = 0;
			this.currentPage = 1;
			this.getData();
		},
		getData () {
			this.$root.request('policyList', {
				policyId: this.policyId,
				title: this.title,
				page: this.currentPage,
				limit: this.pageSize
			}).then((data) => {
				if (data) {
					this.total = data.count;
					this.dataList = data.rows.map(r => {
						r.createdAt = dateFormat(new Date(r.createdAt), 'yyyy-MM-dd hh:mm:ss');
						return r;
					});
				}
			});
		},
		addOrUpdateHandle (id) {
			this.$root.go('/mnt/AddOrUpdatePolicy', { id });
		},
		handleSizeChange (size) {
			this.pageSize = size;
			this.currentPage = 1;
			this.getData();
		},
		handleCurrentChange (page) {
			this.currentPage = page;
			this.getData();
		},
		changeStatus (row) {
			this.$root.request('policyChangeStatus', {
				policyId: row.policyId,
				id: row.id,
				status: row.status
			}).then((data) => {
				if (data) {
					this.getData();
				} else {
					row.status = row.status === 1 ? 2 : 1;
				}
			});
		}
	}
};
</script>
<style lang="less">
	.table-row-img {
		width: 100px;
		height: 50px;
	}
</style>